import { emitter } from "@marvelapp/react-ab-test"
import mixpanel from "mixpanel-browser"
const _ = mixpanel.init(process.env.GATSBY_MIX_TOKEN) //eslint-disable-line

export default function initAb(experiment, variant, userId) {
  emitter.defineVariants("Landing", ["Original", "Referral"])

  if (experiment && variant) {
    emitter.calculateActiveVariant(experiment, userId, variant)
  }

  emitter.addPlayListener(function (experimentName, variantName) {
    mixpanel.track("Experiment: Landing -> shown", {
      Experiment: experimentName,
      Variant: variantName,
    })
  })

  // Called when a 'win' is emitted, in this case by emitter.emitWin('My Example')
  emitter.addWinListener(function (experimentName, variantName) {
    mixpanel.track(`Experiment: Landing -> ${experimentName}`, {
      Experiment: experimentName,
      Variant: variantName,
    })
  })
}
