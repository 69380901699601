/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const { default: initAb } = require("./src/utils/a-b-tester.js")

exports.onClientEntry = () => {
  let experiment, variant

  if (window?.location?.search?.length > 0) {
    experiment = "Landing"
    variant = "Referral"
  }

  const userId =
    typeof window !== "undefined" && window.localStorage.getItem("wait")

  initAb(experiment, variant, userId)
}
